import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FileUpload from '../fileupload/FileUpload';
import ImageModal from '../imagemodal/ImageModal'; // Import the ImageModal component
import BasicTextEditor from '../basictexteditor/BasicTextEditor';  // Import the editor component
import './Modal.css';

interface Job {
  id: number;
  jobBordId: number;
  name: string;
  text: string;
  data: string;
  assignedUsers: string;
  date: string;
  status: string; // "TODO", "IN-WORK", "COMPLETED"
}

interface UploadedFile {
  id: number;
  fileName: string;
  filePath: string;
}

interface JobModalProps {
  job: Job | null;
  onClose: () => void;
  onSave: (job: Job) => void;
  onDelete: (job: Job) => void;
  roadmapId: number;
  jobBordId: number;
}

interface User {
  id: number;
  name: string;
  image: string;
}

const apiBaseUrl = localStorage.getItem("api");
const users = JSON.parse(localStorage.getItem('users') ?? '[]');

const JobModal: React.FC<JobModalProps> = ({ job, onClose, onSave, onDelete, roadmapId, jobBordId }) => {
  const [isEditing, setIsEditing] = useState(job === null);
  const [name, setName] = useState(job?.name || '');
  const [description, setDescription] = useState(JSON.parse(job?.text || '{}').description || '');
  const [status, setStatus] = useState(job?.status || 'TODO');
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [uploadedImages, setUploadedImages] = useState<UploadedFile[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null); // State for enlarged image
  const modalRef = useRef<HTMLDivElement>(null);

  const editorContentRef = useRef<() => string>(() => ''); // Ref to get content from BasicTextEditor

  useEffect(() => {
    if (job) {
      const data = JSON.parse(job.data || '{}');
      setUploadedFiles(data.files || []);
      setUploadedImages(data.images || []);

      let parsedAssignedUsers: any[] = [];
      try {
        parsedAssignedUsers = JSON.parse(job.assignedUsers || '[]');
        if (!Array.isArray(parsedAssignedUsers)) {
          parsedAssignedUsers = [];
        }
      } catch (e) {
        parsedAssignedUsers = [];
      }
      setAssignedUsers(parsedAssignedUsers);
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node) && !isEditing) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [job, isEditing, onClose]);

  const handleSave = () => {
    const info = editorContentRef.current(); // Get the content from BasicTextEditor
    const text = JSON.stringify({ description, info });
    const data = JSON.stringify({ files: uploadedFiles, images: uploadedImages });

    const newJob: Job = {
      id: job?.id || 0,
      jobBordId: jobBordId || 0,
      name,
      text,
      data,
      assignedUsers: JSON.stringify(assignedUsers),
      date: job?.date || new Date().toISOString(),
      status,
    };
    onSave(newJob);
  };

  const handleDelete = async () => {
    if (job) {
      await axios.post(`${apiBaseUrl}/api/Data/deletejob/${job.id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      onDelete(job);
    }
  };

  const handleFileUpload = (newFile: UploadedFile) => {
    setUploadedFiles([...uploadedFiles, newFile]);
  };

  const handleImageUpload = (newImage: UploadedFile) => {
    setUploadedImages([...uploadedImages, newImage]);
  };

  const handleImageDelete = async (image: UploadedFile) => {
    setUploadedImages(uploadedImages.filter((i) => i.id !== image.id));
  }

  const handleUserAssign = (userId: string) => {
    setAssignedUsers([...assignedUsers, userId]);
  };

  const handleUserRemove = (userId: string) => {
    setAssignedUsers(assignedUsers.filter(id => id !== userId));
  };

  const handleImageClick = (url: string) => {
    setEnlargedImage(url);
  };

  return (
    <div className="modal-backdrop">
      <div className="modal" ref={modalRef}>
        {isEditing ? (
          <>
            <h2>{job ? 'Edit Job' : 'Create Job'}</h2>
            <div>
              <label>Name:</label>
              <input
                type="text"
                placeholder="Job Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label>Description:</label>
              <textarea
                placeholder="Job Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <label>Info:</label>
              <BasicTextEditor
                getContentRef={editorContentRef}
                initialContent={JSON.parse(job?.text || '{}').info || ''} // Pass the existing content as initialContent
              />
            </div>

            <div>
              <h4>Uploaded Files:</h4>
              <ul>
                {uploadedFiles.map(file => (
                  <li key={file.id}>{file.fileName}</li>
                ))}
              </ul>
            </div>

            <div>
              <h4>Uploaded Images:</h4>
              <ul className="image-list">
                {uploadedImages.map(image => (
                  <li key={image.id}>
                    <img
                      src={`${apiBaseUrl}/api/Image/${image.fileName}`}
                      alt={image.fileName}
                      className="preview-image"
                      onClick={() => handleImageClick(`${apiBaseUrl}/api/Image/${image.fileName}`)}
                    />
                    <button onClick={() => handleImageDelete(image)} className="remove-btn">x</button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="upload-buttons">
              <FileUpload 
                onUploadComplete={handleFileUpload} 
                label="Upload File" 
                jobBordId={jobBordId}
                roadmapId={roadmapId}
              />
              <FileUpload 
                onUploadComplete={handleImageUpload} 
                label="Upload Image" 
                jobBordId={jobBordId} 
                roadmapId={roadmapId}
              />
            </div>

            <div>
              <h4>Assigned Users:</h4>
              <div className="assigned-users-list">
                {assignedUsers.map((userId) => {
                  const user = users.find((u: User) => u.id === parseInt(userId));
                  return user ? (
                    <div key={user.id} className="assigned-user">
                      <img src={user.image} alt={user.name} className="user-avatar" />
                      <button onClick={() => handleUserRemove(userId)} className="remove-btn">x</button>
                    </div>
                  ) : null;
                })}
              </div>
              <select onChange={(e) => handleUserAssign(e.target.value)}>
                <option value="">Assign User</option>
                {users.map((user: User) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="modal-actions">
              <button onClick={handleSave}>Save</button>
              {job && <button onClick={handleDelete}>Delete</button>}
              <button onClick={onClose}>Cancel</button>
            </div>
          </>
        ) : (
          <>
            <h2 className="job-title">{name}</h2>
            <p className="description-text"><strong>Description:</strong> {description}</p>
            <div className="info-section" dangerouslySetInnerHTML={{ __html: JSON.parse(job?.text || '{}').info || '' }} />
            <h4>Files:</h4>
            <ul>
              {uploadedFiles.map(file => (
                <li key={file.id}>{file.fileName}</li>
              ))}
            </ul>
            <h4>Images:</h4>
            <ul className="image-list">
              {uploadedImages.map(image => (
                <li key={image.id}>
                  <img
                    src={`${apiBaseUrl}/api/Image/${image.fileName}`}
                    alt={image.fileName}
                    className="preview-image"
                    onClick={() => handleImageClick(`${apiBaseUrl}/api/Image/${image.fileName}`)}
                  />
                </li>
              ))}
            </ul>
            <h4>Assigned Users:</h4>
            <div className="assigned-users-list">
              {assignedUsers.map((userId) => {
                const user = users.find((u: User) => u.id === parseInt(userId));
                return user ? (
                  <img key={user.id} src={user.image} alt={user.name} className="user-avatar" />
                ) : null;
              })}
            </div>
            <div className="modal-actions">
              <button onClick={() => setIsEditing(true)}>Edit</button>
              <button onClick={onClose}>Close</button>
            </div>
          </>
        )}
      </div>
      {enlargedImage && <ImageModal url={enlargedImage} onClose={() => setEnlargedImage(null)} />}
    </div>
  );
};

export default JobModal;
