import React, { useState, useEffect, useRef } from 'react';
import { IoChatboxEllipsesSharp } from "react-icons/io5";
import axios from 'axios';
import { ChatMessage } from '../types';
import ImageModal from '../imagemodal/ImageModal'; // Import the ImageModal component 
import './UsersChat.css';

interface User {
  id: number;
  name: string;
  image: string;
  online: number;
}

const apiBaseUrl = localStorage.getItem("api");

const UsersChat: React.FC<{ connection: any }> = ({ connection }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [isUserListOpen, setIsUserListOpen] = useState<boolean>(false);
  const [chatBubbles, setChatBubbles] = useState<{ user: User, unreadCount: number }[]>([]);
  const [skipCount, setSkipCount] = useState<number>(0);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null); // State for enlarged image
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const userData = JSON.parse(localStorage.getItem('user') ?? '');
  const userListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (connection) {
      connection.on("ReceiveMessage", (serializedMessage: string) => {
        const message: ChatMessage = JSON.parse(serializedMessage);
        if (selectedUser && selectedUser.id === message.senderId) {
          setMessages(prevMessages => [...prevMessages, message]);
          scrollToBottom();
        } else {
          const user = users.find(u => u.id === message.senderId);
          if (user) {
            setChatBubbles(prevBubbles => {
              const existingBubble = prevBubbles.find(b => b.user.id === user.id);
              if (existingBubble) {
                return prevBubbles.map(b =>
                  b.user.id === user.id
                    ? { ...b, unreadCount: b.unreadCount + 1 }
                    : b
                );
              } else {
                return [...prevBubbles, { user, unreadCount: 1 }];
              }
            });
          }
        }
      });

      connection.on("ReceiveAllUsersStatus", (allUsersStatus: { userId: number, online: number }[]) => {
        setUsers(prevUsers => 
            prevUsers.map(user => {
                const userStatus = allUsersStatus.find(status => status.userId === user.id);
                if (userStatus) {
                    return { ...user, online: userStatus.online };
                }
                return user;
            })
        );
      });

      return () => {
        connection.off("ReceiveMessage");
        connection.off("ReceiveAllUsersStatus");
      };
    }
  }, [connection, selectedUser, users]);

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem('users') ?? '[]');
    setUsers(users);

    const handleClickOutside = (event: MouseEvent) => {
      if (userListRef.current && !userListRef.current.contains(event.target as Node)) {
        setIsUserListOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserClick = (user: User) => {
    setSelectedUser(user);
    setIsUserListOpen(false);
    setSkipCount(0);

    setChatBubbles(prevBubbles => {
      const existingBubble = prevBubbles.find(b => b.user.id === user.id);
      if (existingBubble) {
        return prevBubbles.map(b =>
          b.user.id === user.id
            ? { ...b, unreadCount: 0 }
            : b
        );
      } else {
        return [...prevBubbles, { user, unreadCount: 0 }];
      }
    });

    fetchChatHistory(user, 0, 25);
    scrollToBottom();
  };

  const fetchChatHistory = (user: User, skip: number, take: number) => {
    axios.get(`${apiBaseUrl}/api/Users/chat-history/${userData.id}/${user.id}?skip=${skip}&take=${take}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    })
      .then(response => {
        if (skip === 0) {
          setMessages(response.data);
        } else {
          setMessages(prevMessages => [...response.data, ...prevMessages]);
        }
        scrollToBottom();
      })
      .catch(error => console.error('Error fetching chat history:', error));
  };

  const handleLoadOlderMessages = () => {
    if (selectedUser) {
      const newSkipCount = skipCount + 25;
      setSkipCount(newSkipCount);
      fetchChatHistory(selectedUser, newSkipCount, 25);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && previewImages.length === 0) return;
    if(!selectedUser) return;
    const message: ChatMessage = {
      id: Math.random() * 1000000000,
      senderId: userData.id,
      receiverId: selectedUser.id,
      message: newMessage,
      dateTime: new Date().toISOString(),
    };

    const dbMessage: ChatMessage = {
      id: 0,
      senderId: userData.id,
      receiverId: selectedUser.id,
      message: newMessage,
      dateTime: new Date().toISOString(),
    };

    axios.post(`${apiBaseUrl}/api/Users/send-message`, dbMessage, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    });

    try {
      await connection.invoke("SendDirectMessage", selectedUser.id.toString(), userData.id, newMessage);
      setMessages(prevMessages => [...prevMessages, message]);
      setNewMessage('');
      setPreviewImages([]);
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = event.clipboardData.items;
    const files: File[] = [];
    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          // check if file is an image
          if (file.type.startsWith('image')) {
            files.push(file);
          } else {
            console.log('Only images are allowed');
            return;
          }
        }
      }
    }

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));
      formData.append('roomid', selectedUser?.id.toString() ?? '0');

      try {
        const uploadResponse = await axios.post(`${apiBaseUrl}/api/ChatRoom/upload`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
        const uploadedFilesUrls = uploadResponse.data.map((fileName: string) => `${apiBaseUrl}/api/Image/${fileName}`);
        setPreviewImages((prevImages) => [...prevImages, ...uploadedFilesUrls]);
        setNewMessage((prevMessage) => prevMessage + uploadedFilesUrls.map((url: string) => `<img width="500" src="${url}" alt="image" class="chat-thumbnail" />`).join(' '));
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleImageClick = (url: string) => {
    setEnlargedImage(url);
  };

  const handleCloseChat = (userId: number) => {
    if (selectedUser?.id === userId) {
      setSelectedUser(null);
    }
  };

  const handleCloseUser = (userId: number) => {
    setChatBubbles(chatBubbles.filter(b => b.user.id !== userId));

    if (selectedUser?.id === userId) {
      setSelectedUser(null);
    }
  };

  const handleReopenChat = (user: User) => {
    setSelectedUser(user);
    setSkipCount(0);
    setChatBubbles(prevBubbles => prevBubbles.map(b => b.user.id === user.id ? { ...b, unreadCount: 0 } : b));
    fetchChatHistory(user, 0, 25);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    }, 100);
  };

  return (
    <div>
      <div className="chat-icon" onClick={() => setIsUserListOpen(true)}>
        <IoChatboxEllipsesSharp size={32} />
      </div>

      {isUserListOpen && (
        <div className="chat-container" ref={userListRef}>
          <div className="user-list">
            {users.map(user => user.id !== userData.id && (
              <div key={user.id} className="user-item" onClick={() => handleUserClick(user)}>
                <img src={`${user.image}`} alt={user.name} className="user-avatar" />
                <span>{user.name}</span>
                <span className={`online-status ${user.online == 1 ? 'online' : 'offline'}`}></span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="chat-bubbles">
        {chatBubbles.map(bubble => (
          <div key={bubble.user.id} className="chat-bubble">
            <img src={`${bubble.user.image}`} alt={bubble.user.name} onClick={() => handleReopenChat(bubble.user)} />
            {bubble.unreadCount > 0 && <div className="unread-count">{bubble.unreadCount}</div>}
            <button className="close-bubble" onClick={() => handleCloseUser(bubble.user.id)}>X</button>
          </div>
        ))}
      </div>

      {selectedUser && (
        <div className="chat-window">
          <div className="chat-header">
            <img src={`${selectedUser.image}`} alt={selectedUser.name} className="user-avatar-header" />
            <span>{selectedUser.name}</span>
            <span className={`online-status-window ${selectedUser.online == 1 ? 'online' : 'offline'}`}></span>
            <button className="close-chat" onClick={() => handleCloseChat(selectedUser.id)}>X</button>
          </div>
          <div className="chat-history" ref={chatHistoryRef}>
            <button className="load-older-messages" onClick={handleLoadOlderMessages}>Load Older Messages</button>
            {messages.map(msg => (
              <div key={msg.id} className={`chat-message ${msg.senderId === userData.id ? 'sent' : 'received'}`}>
                <div dangerouslySetInnerHTML={{ __html: msg.message.replace(/\n/g, '<br />') }} onClick={(e) => {
                  const target = e.target as HTMLImageElement;
                  if (target.tagName === 'IMG') {
                    handleImageClick(target.src);
                  }
                }}></div>
                <div className="message-time">{new Date(msg.dateTime).toLocaleTimeString()}</div>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <div className="image-previews">
              {previewImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt="preview"
                  className="preview-image"
                  onClick={() => handleImageClick(url)}
                />
              ))}
            </div>
            <textarea 
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message"
              onPaste={handlePaste}
              onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) { e.preventDefault(); handleSendMessage(); } }}
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
      {enlargedImage && <ImageModal url={enlargedImage} onClose={() => setEnlargedImage(null)} />}
    </div>
  );
};

export default UsersChat;
