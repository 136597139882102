import React, { useState, useEffect, useRef } from 'react';
import { FaCog, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ProfileModal from './ProfileModal';
import './TopBar.css';

const TopBar: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [user, setUser] = useState<{ name: string; image?: string } | null>(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        setUser(JSON.parse(userData));
      }
    };

    loadUserData();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'user') {
        loadUserData();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');

    // Navigate back to the login page
    navigate('/login', { replace: true });
    window.location.reload();
  };

  return (
    <div className="topbar">
      <div className="left-section">
        {/* Left section if you have any content */}
      </div>
      <div className="user-actions">
        {user && (
          <>
            <div className="user-icon-container" onClick={toggleDropdown}>
              <img
                src={user.image || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                alt="User Profile"
                className="user-icon"
              />
            </div>
          </>
        )}
        {isDropdownOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
            <ul>
              <li onClick={toggleProfileModal}><FaUser /> Profile</li>
              <li onClick={handleLogout}><FaSignOutAlt /> Logout</li>
            </ul>
          </div>
        )}
        {isProfileModalOpen && user && (
          <ProfileModal user={user} onClose={toggleProfileModal} />
        )}
      </div>
    </div>
  );
};

export default TopBar;
