import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApplicantReader.css';

interface FormSubmission {
  id: number;
  name: string;
  email: string;
  discordId: string;
  role: string;
  about: string;
  previousWork: string;
  submittedAt: string;
}

const ApplicantReader: React.FC = () => {
  const [submissions, setSubmissions] = useState<FormSubmission[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<FormSubmission | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get<FormSubmission[]>(`${localStorage.getItem("api")}/api/Users/get-submissions`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
        setSubmissions(response.data);
      } catch (error) {
        console.error('Error fetching submissions:', error);
        setErrorMessage('Nepodařilo se načíst přihlášky.');
      }
    };

    fetchSubmissions();
  }, []);

  const handleClick = (submission: FormSubmission) => {
    setSelectedSubmission(submission);
  };

  return (
    <div className="form-reader">
      <h2>Seznam přihlášek</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <ul className="submission-list">
        {submissions.map((submission) => (
          <li key={submission.id} onClick={() => handleClick(submission)}>
            <div><strong>Jméno:</strong> {submission.name}</div>
            <div><strong>Email:</strong> {submission.email}</div>
            <div><strong>Discord ID:</strong> {submission.discordId}</div>
            <div><strong>Čas odeslání:</strong> {new Date(submission.submittedAt).toLocaleString()}</div>
          </li>
        ))}
      </ul>

      {selectedSubmission && (
        <div className="submission-details">
          <h3>Podrobnosti přihlášky</h3>
          <p><strong>Jméno:</strong> {selectedSubmission.name}</p>
          <p><strong>Email:</strong> {selectedSubmission.email}</p>
          <p><strong>Discord ID:</strong> {selectedSubmission.discordId}</p>
          <p><strong>Role:</strong> {selectedSubmission.role}</p>
          <p><strong>O sobě:</strong> <div dangerouslySetInnerHTML={{ __html: selectedSubmission.about }} /></p>
          <p><strong>Čas odeslání:</strong> {new Date(selectedSubmission.submittedAt).toLocaleString()}</p>
        </div>
      )}
    </div>
  );
};

export default ApplicantReader;
