import React from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import Roadmap from '../roadmap/Roadmap';
import FileBrowser from '../filebrowser/FileBrowser';
import TextureLibrary from '../TextureLibrary/TextureLibrary';
import UsersChat from '../userchat/UsersChat';
import Chat from '../chat/Chat';
import ImageResize from '../imageresize/ImageResize';
import ApplicantReader from '../applicantreader/ApplicantReader';
import GameDatabase from '../gamedatabase/GameDatabase';
import './Dashboard.css';
import { ChatMessage } from '../types';


const Dashboard: React.FC<{ connection: any }> = ({ connection }) => {
  const [activeSection, setActiveSection] = React.useState<any>('dashboard');

  const renderContent = () => {
    switch (activeSection) {
      case 'roadmap':
        return <Roadmap />;
      case 'filebrowser':
        return <FileBrowser />;
        case 'library':
          return <TextureLibrary />;
      case 'chat':
        return <Chat connection = {connection} />;
      case 'imageresize':
        return <ImageResize />;
      case 'formreader':
        return <ApplicantReader />;
      case 'gamedb':
        return <GameDatabase />;
      case 'dashboard':
      default:
        return (
          <div className="welcome">
            Vítejte na stránce The Rise of Reflex Military Roleplay DB alpha! <br />
            Tato platforma slouží k rozdělování úkolů, zálohování souborů a sledování vývoje projektu.<br />
            Prosím, využívejte jednotlivé sekce podle potřeby a mějte přehled o tom, co se děje v rámci projektu.
          </div>
        );
    }
  };

  return (
    <div className="dashboard">
      <TopBar />
      <UsersChat connection={connection} />
      <Sidebar setActiveSection={setActiveSection} />
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
