import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login: React.FC = () => {
  const [name, setName] = useState('');  // Change from username to name
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    const apiBaseUrl = localStorage.getItem('api');

    try {
      const response = await fetch(`${apiBaseUrl}/api/Auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, password }),  // Use 'name' instead of 'username'
      });

      if (response.ok) {
        const data = await response.json();

        const userData = {
          id: data.user.id,
          name: data.user.name,
          image: data.user.image || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
          role : data.user.role,
          jwt: data.token,
        };

        localStorage.setItem('jwt', userData.jwt);
        localStorage.setItem('user', JSON.stringify(userData));

        navigate('/dashboard', { replace: true });
        window.location.reload();
      } else {
        setError('Invalid credentials');
      }
    } catch (error) {
      setError('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="Login">
      <div className="Login-header">
        The Rise of Reflex Military 
      </div>
      <form className="Login-form" onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={name}  // Update to use 'name'
          onChange={(e) => setName(e.target.value)}  // Update to use 'setName'
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
