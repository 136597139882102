import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Home from './components/home/Home';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from 'axios';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(checkTokenValidity());
  const [connection, setConnection] = useState<any>(null);
  localStorage.setItem('api', 'https://edynu.eu');


  function checkTokenValidity() {
    const token = localStorage.getItem('jwt');
    if (token) {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem('jwt');
        alert('Session expired. Please login again.');
        return false;
      } else {
        console.log('Token expires in:', (exp * 1000 - Date.now()) / 60000, 'minutes');
        setTimeout(() => {
          localStorage.removeItem('jwt');
          alert('Session expired. Please login again.');
          window.location.reload();
        }, exp * 1000 - Date.now());
      }
      return true;
    }
    return false;
  }

  const initConnection = () => {
    if (isAuthenticated) {
      axios.get(`https://edynu.eu/api/Users/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      })
        .then(response => localStorage.setItem('users', JSON.stringify(response.data)))
        .catch(error => console.error('Error fetching users:', error));

      const connection = new HubConnectionBuilder()
        .withUrl("https://edynu.eu/hubs/chat", {
          accessTokenFactory: () => localStorage.getItem('jwt') || ''
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect([0, 2000, 10000])
        .build();

      connection.onclose((error) => {
        if (error) {
          console.error("Connection closed due to error:", error);
        } else {
          console.log("Connection closed.");
        }
      });

      connection.start()
        .then(() => {
          console.log("Connected to SignalR hub");
          connection.invoke("RegisterUserId", JSON.parse(localStorage.getItem('user') ?? '{}').id.toString());
        })
        .catch((err) => console.error("Error during connection start:", err));

      setConnection(connection);

      return () => {
        connection.stop();
      };
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(checkTokenValidity());
    };

    initConnection();
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Router>
      {isAuthenticated ? (
          <Routes>
            <Route path="/login" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={(
              <Dashboard
                connection={connection}
              />
            )} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
