import React, { useState, useEffect, useRef } from 'react';
import './CreateTextureModal.css'; 
import axios from 'axios';
import BasicTextEditor from '../basictexteditor/BasicTextEditor';

const apiBaseUrl = localStorage.getItem("api");

interface TextureType {
  id: number;
  name: string;
  mapConfiguration: string[]; // Array of map names (e.g., ["default", "bump"])
}

interface CreateTextureModalProps {
  onClose: () => void;
  onSave: () => void;
  folderId: number; // Added folderId to props
}

const ddsFormats = [
  { label: "DXT1", value: "DXT1" },
  { label: "DXT3", value: "DXT3" },
  { label: "DXT5", value: "DXT5" },
  { label: "ATI1", value: "ATI1" },
  { label: "ATI2", value: "ATI2" },
  { label: "BC7", value: "BC7" },
  { label: "A8R8G8B8", value: "A8R8G8B8" },
  { label: "X8R8G8B8", value: "X8R8G8B8" },
  { label: "A1R5G5B5", value: "A1R5G5B5" },
  { label: "A8", value: "A8" },
  { label: "A8B8G8R8", value: "A8B8G8R8" },
  { label: "L8", value: "L8" },
];

const CreateTextureModal: React.FC<CreateTextureModalProps> = ({ onClose, onSave, folderId }) => {
  const [textureName, setTextureName] = useState<string>('');
  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
  const [textureTypes, setTextureTypes] = useState<TextureType[]>([]);
  const [files, setFiles] = useState<Record<string, File | null>>({});
  const [selectedDDSFormats, setSelectedDDSFormats] = useState<Record<string, string>>({});

  const [isTypeModalOpen, setIsTypeModalOpen] = useState<boolean>(false);
  const [newTypeName, setNewTypeName] = useState<string>('');
  const [newMapConfiguration, setNewMapConfiguration] = useState<string[]>([]);

  const editorContentRef = useRef<() => string>(() => ''); // Ref for BasicTextEditor content

  useEffect(() => {
    fetchTextureTypes();
  }, []);

  const fetchTextureTypes = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/Library/gettexturetypes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      const data = await response.json();

      // Ensure mapConfiguration is an array
      const parsedData = data.map((type: any) => ({
        ...type,
        mapConfiguration: Array.isArray(type.mapConfiguration)
          ? type.mapConfiguration
          : JSON.parse(type.mapConfiguration || "[]"),
      }));

      setTextureTypes(parsedData);
    } catch (error) {
      console.error('Error fetching texture types:', error);
    }
  };

  const handleTextureTypeChange = (typeId: number) => {
    setSelectedTypeId(typeId);
    setFiles({});
    setSelectedDDSFormats({});
  };

  const handleSaveTexture = async () => {
    const description = editorContentRef.current(); // Get the content from BasicTextEditor

    if (!textureName || !selectedTypeId) {
        alert('Please provide a name and select a texture type.');
        return;
    }

    const formData = new FormData();
    formData.append('textureName', textureName);
    formData.append('description', description); // Include the description from BasicTextEditor
    formData.append('textureTypeId', selectedTypeId.toString());
    formData.append('folderId', folderId.toString());

    Object.keys(files).forEach((mapName) => {
        const file = files[mapName];
        if (file) {
            formData.append('files', file);
            formData.append(`ddsFormat_${file.name}`, selectedDDSFormats[mapName]); // Add DDS format per file
            formData.append(`textureType_${file.name}`, mapName); // Add texture type per file
        }
    });

    try {
        await axios.post(`${apiBaseUrl}/api/Library/createtexture`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
        });
        onSave(); // Callback after saving
    } catch (error) {
        console.error('Error saving texture:', error);
    }
  };

  const handleDDSFormatChange = (mapName: string, format: string) => {
    setSelectedDDSFormats({ ...selectedDDSFormats, [mapName]: format });
  };

  const handleAddMap = () => {
    setNewMapConfiguration([...newMapConfiguration, '']);
  };

  const handleMapChange = (index: number, value: string) => {
    const updatedMaps = [...newMapConfiguration];
    updatedMaps[index] = value;
    setNewMapConfiguration(updatedMaps);
  };

  const handleRemoveMap = (index: number) => {
    const updatedMaps = [...newMapConfiguration];
    updatedMaps.splice(index, 1);
    setNewMapConfiguration(updatedMaps);
  };

  const handleSaveTextureType = async () => {
    if (!newTypeName || newMapConfiguration.length === 0) {
      alert('Please provide a type name and at least one map configuration.');
      return;
    }

    const newType = {
      id: textureTypes.length + 1,
      name: newTypeName,
      mapConfiguration: JSON.stringify(newMapConfiguration),
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/api/Library/createtexturetype`, newType, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      setTextureTypes([...textureTypes, response.data]);
      setIsTypeModalOpen(false);
    } catch (error) {
      console.error('Error saving texture type:', error);
    }
  };

  const handleFileChange = (mapName: string, file: File | null) => {
    setFiles({ ...files, [mapName]: file });
  };

   return (
    <div className="create-texture-modal-backdrop">
      <div className="create-texture-modal">
        <h3>Create New Texture</h3>
        <label>Texture Name:</label>
        <input
          type="text"
          placeholder="Texture Name"
          value={textureName}
          onChange={(e) => setTextureName(e.target.value)}
          className="texture-input"
        />
        <label>Description:</label>
        <BasicTextEditor getContentRef={editorContentRef} /> {/* Replace ReactQuill with BasicTextEditor */}
        
        Texture Type:
        <div className="texture-type-line">
          <select
            value={selectedTypeId || ''}
            onChange={(e) => handleTextureTypeChange(parseInt(e.target.value))}
            className="texture-select"
          >
            <option value="">Select Texture Type</option>
            {textureTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
          <button onClick={() => setIsTypeModalOpen(true)} className="add-type-btn">
            + Add Type
          </button>
        </div>
        {selectedTypeId && (
          <div className="file-uploads-grid">
            {textureTypes
              .find((type) => type.id === selectedTypeId)
              ?.mapConfiguration.map((mapName) => (
                <div key={mapName} className="file-upload-item">
                  <div className="upload-preview" onClick={() => document.getElementById(`file-input-${mapName}`)?.click()}>
                    {files[mapName] ? (
                      <img
                        src={URL.createObjectURL(files[mapName]!)}
                        alt={mapName}
                        className="uploaded-image"
                      />
                    ) : (
                      <div className="upload-placeholder">+</div>
                    )}
                  </div>
                  <label className="map-name-label">{mapName}</label>
                  <input
                    id={`file-input-${mapName}`}
                    type="file"
                    onChange={(e) => handleFileChange(mapName, e.target.files ? e.target.files[0] : null)}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                  <select
                    value={selectedDDSFormats[mapName] || ''}
                    onChange={(e) => handleDDSFormatChange(mapName, e.target.value)}
                    className="dds-format-select"
                  >
                    <option value="">Select DDS Format</option>
                    {ddsFormats.map((format) => (
                      <option key={format.value} value={format.value}>
                        {format.label}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
          </div>
        )}
        <div className="texture-modal-actions">
          <button onClick={handleSaveTexture} className="save-texture-btn">
            Save Texture
          </button>
          <button onClick={onClose} className="close-modal-btn">
            Cancel
          </button>
        </div>
      </div>

      {isTypeModalOpen && (
        <div className="create-texture-modal-backdrop">
          <div className="create-texture-modal">
            <h3>Create Texture Type</h3>
            <input
              type="text"
              placeholder="Texture Type Name"
              value={newTypeName}
              onChange={(e) => setNewTypeName(e.target.value)}
              className="texture-input"
            />
            <div className="map-configurations">
              {newMapConfiguration.map((map, index) => (
                <div key={index} className="map-config-item">
                  <input
                    type="text"
                    placeholder="Map Name"
                    value={map}
                    onChange={(e) => handleMapChange(index, e.target.value)}
                    className="map-input"
                  />
                  <button onClick={() => handleRemoveMap(index)} className="remove-map-btn">
                    -
                  </button>
                </div>
              ))}
              <button onClick={handleAddMap} className="add-map-btn">
                + Add Map
              </button>
            </div>
            <div className="texture-modal-actions">
              <button onClick={handleSaveTextureType} className="save-texture-type-btn">
                Save Texture Type
              </button>
              <button onClick={() => setIsTypeModalOpen(false)} className="close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateTextureModal;
