import React, { useState, useEffect } from 'react';
import './Roadmap.css';
import RoadmapModal from './RoadmapModal';
import Bord from './Bord';
import { FaPlusCircle } from "react-icons/fa";

interface Roadmap {
  id: number;
  name: string;
  description: string;
  position: number;
  date: string;
  jobBords: JobBord[];
}

interface JobBord {
  id: number;
  name: string;
  description: string;
  position: number;
  date: string;
  roadmapId: number;
}

const RoadmapComponent: React.FC = () => {
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [selectedRoadmap, setSelectedRoadmap] = useState<Roadmap | null>(null);
  const [selectedBord, setSelectedBord] = useState<JobBord | null>(null);
  const [isRoadmapModalOpen, setIsRoadmapModalOpen] = useState(false);
  const [isBordModalOpen, setIsBordModalOpen] = useState(false);

  useEffect(() => {
    const fetchRoadmaps = async () => {
      const apiBaseUrl = localStorage.getItem('api');
      const data = await fetchFromApi(`${apiBaseUrl}/api/data/getroadmaps`);
      if (data) {
        setRoadmaps(Array.isArray(data) ? data : []);

      }
    };

    fetchRoadmaps();
  }, []);

  const handleRoadmapClick = (roadmap: Roadmap) => {
    // jobbords are sorted by date resorting them by position
    roadmap.jobBords.sort((a, b) => a.position - b.position);
    setSelectedRoadmap(roadmap);
    setSelectedBord(null); // Reset selectedBord when a new roadmap is selected
  };

  const handleBordClick = (bord: JobBord) => {
    setSelectedBord(bord);
  };

  const fetchFromApi = async (url: string, method: string = 'GET', body: any = null) => {
    const token = localStorage.getItem('jwt');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        console.error(`Failed to fetch ${url}:`, response.statusText);
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      return null;
    }
  };

  const handleOpenRoadmapModal = () => {
    setIsRoadmapModalOpen(true);
  };

  const handleCloseRoadmapModal = () => {
    setIsRoadmapModalOpen(false);
  };

  const handleOpenBordModal = () => {
    setIsBordModalOpen(true);
  };

  const handleCloseBordModal = () => {
    setIsBordModalOpen(false);
  };

  const handleNewRoadmap = async (newRoadmap: Roadmap) => {
    const apiBaseUrl = localStorage.getItem('api');

    const apiRoadmap = {
      id: 0, // Set id to 0 or remove it
      name: newRoadmap.name,
      description: newRoadmap.description,
      date: new Date().toISOString(),
      position: roadmaps.length + 1, // Set position based on the number of existing roadmaps 
      jobBords: [],
    };

    const createdRoadmap = await fetchFromApi(`${apiBaseUrl}/api/Data/newroadmap`, 'POST', apiRoadmap);

    if (createdRoadmap) {
      setRoadmaps([createdRoadmap, ...roadmaps]);
      setSelectedRoadmap(createdRoadmap);
    }

    setIsRoadmapModalOpen(false);
  };

  const handleNewBord = async (newBord: { name: string; description: string }) => {
    if (!selectedRoadmap) return;

    const apiBaseUrl = localStorage.getItem('api');

    const apiBord = {
      id: 0, // ID will be assigned by the server
      position: selectedRoadmap.jobBords.length + 1, // Set position based on the number of existing job boards
      name: newBord.name,
      description: newBord.description,
      roadmapId: selectedRoadmap.id, // Associate with the selected roadmap
      date: new Date().toISOString(),
    };

    const createdBord = await fetchFromApi(`${apiBaseUrl}/api/Data/newjobbord`, 'POST', apiBord);

    if (createdBord) {
      setSelectedRoadmap({
        ...selectedRoadmap,
        jobBords: [...selectedRoadmap.jobBords, createdBord],
      });
    }

    setIsBordModalOpen(false);
  };

  return (
    
    <div className="roadmap-container">
      <div className="roadmap-list">
        {roadmaps.map((roadmap) => (
          <div
            key={roadmap.id}
            className="roadmap-box"
            onClick={() => handleRoadmapClick(roadmap)}
          >
            <span>{roadmap.name}</span>
          </div>
        ))}
        <button className="create-roadmap-button" onClick={handleOpenRoadmapModal}>
        <FaPlusCircle />
        </button>
      </div>
        {!selectedBord ? (
          <>
            {selectedRoadmap && (
              <div className="bord-view">
                <h3>{selectedRoadmap.name}</h3>
                <p className="bord-description">{selectedRoadmap.description}</p>
                <div className="bords-view">
                  {selectedRoadmap.jobBords.map((bord) => (
                    <div key={bord.id} className="jobbord-box" onClick={() => handleBordClick(bord)}>
                      <h3>{bord.name}</h3>
                      <p>{bord.description}</p>
                    </div>
                  ))}
                  <button className="create-bord-button" onClick={handleOpenBordModal}>
                    <FaPlusCircle />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Bord jobBord={selectedBord} />
        )}

        {isRoadmapModalOpen && (
          <RoadmapModal
            onClose={handleCloseRoadmapModal}
            onCreate={handleNewRoadmap}
            title="Create New Roadmap" />
        )}

        {isBordModalOpen && (
          <RoadmapModal
            onClose={handleCloseBordModal}
            onCreate={handleNewBord}
            title="Create New Bord" />
        )}
      </div>
  );
};

export default RoadmapComponent;
