import React, { useState } from 'react';
import './Modal.css';

interface JobBord {
    id: number;
    name: string;
    description: string;
    position: number;
    date: string;
    roadmapId: number;
  }

interface RoadmapModalProps {
  onClose: () => void;
  onCreate: (newRoadmap: { id: number; name: string; description: string; date: string, position: number, jobBords: JobBord[] }) => void;
  title: string; // Add the title prop to the interface
}

const RoadmapModal: React.FC<RoadmapModalProps> = ({ onClose, onCreate, title }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleCreate = () => {
    const newRoadmap = {
      id: Date.now(), // Temporary ID
      name,
      description,
      position: 0,
      date: new Date().toISOString(),
      jobBords: []
    };
    onCreate(newRoadmap);
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>{title}</h2> {/* Use the title prop here */}
        <input
          type="text"
          placeholder={`${title.split(' ')[2]} Name`} // Dynamic placeholder
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <textarea
          placeholder={`${title.split(' ')[2]} Description`} // Dynamic placeholder
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="modal-actions">
          <button onClick={handleCreate}>Create</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default RoadmapModal;
