import React, { useState, useRef } from 'react';
import axios from 'axios';
import BasicTextEditor from '../basictexteditor/BasicTextEditor';  // Import the editor component
import SuccessModal from './SuccessModal';  // Import the SuccessModal component
import './JoinUsForm.css';

const JoinUsForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    discordId: '',
    role: '',
    previousWork: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission
  const editorContentRef = useRef<() => string>(() => ''); // Create a ref for getting editor content
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isSubmitting) {
      return; // Prevent form submission if it wasn't explicitly triggered by the button
    }

    const editorContent = editorContentRef.current(); // Get the content from the editor

    // Ensure that all required fields are filled out
    if (!formData.name || !formData.email || !formData.discordId || !formData.role || !editorContent) {
      setErrorMessage('Prosím vyplňte všechna povinná pole.');
      setIsSubmitting(false); // Reset the submission state
      return;
    }

    try {
      const response = await axios.post(`${localStorage.getItem("api")}/api/Users/submit-application`, {
        ...formData,
        about: editorContent, // Add editor content to the form data upon submission
      });
      console.log('Form submitted successfully:', response.data);
      setErrorMessage(null);
      setShowSuccessModal(true);

      // Clear the form after submission
      setFormData({
        name: '',
        email: '',
        discordId: '',
        role: '',
        previousWork: '',
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        setErrorMessage(error.response.data.error);
      } else {
        console.error('Error submitting form:', error);
      }
    } finally {
      setIsSubmitting(false); // Reset the submission state
    }
  };

  const handleButtonClick = () => {
    setIsSubmitting(true); // Set the form as submitting
  };

  return (
    <>
      <form className="join-us-form" onSubmit={handleSubmit} noValidate>
        <h2>Připoj se k nám</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <label htmlFor="name">Jméno / Přezdívka:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="discordId">Discord ID:</label>
        <input
          type="text"
          id="discordId"
          name="discordId"
          value={formData.discordId}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="role">S čím by si se rád připojil?</label>
        <select
          id="role"
          name="role"
          value={formData.role}
          onChange={handleInputChange}
          required
        >
          <option value="">Vyber možnost...</option>
          <option value="3D artist">3D umělec</option>
          <option value="2D artist">2D umělec</option>
          <option value="lua">Lua vývojář</option>
          <option value="csharp">C# vývojář</option>
          <option value="js">JS/TS vývojář</option>
          <option value="cloth">Vývojář oblečení</option>
          <option value="vehicle">Vývojář vozidel</option>
          <option value="map">Stavitel mapy</option>
          <option value="learner">Jsem ochoten se učit</option>
        </select>

        <label htmlFor="about">Něco o sobě:</label>
        <BasicTextEditor getContentRef={editorContentRef} />

        <button type="submit" className="submit-button" onClick={handleButtonClick}>Odeslat přihlášku</button>
      </form>

      {showSuccessModal && (
        <SuccessModal message="Přihláška byla úspěšně odeslána!" onClose={() => setShowSuccessModal(false)} />
      )}
    </>
  );
};

export default JoinUsForm;
