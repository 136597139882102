import React, { useEffect, useState } from 'react';
import './TextureDetailsModal.css';
import axios from 'axios';
import ImageModal from '../imagemodal/ImageModal';

interface TextureDetailsModalProps {
    texture: any;
    onClose: () => void;
    textureType: any;
}

const apiBaseUrl = localStorage.getItem("api");

const TextureDetailsModal: React.FC<TextureDetailsModalProps> = ({ texture, onClose, textureType }) => {
    const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        const urls: { [key: string]: string } = {};
        for (const mapName of textureType.mapConfiguration) {
            urls[mapName] = `${apiBaseUrl}/api/Library/${texture.name}/${mapName}`;
        }
        setImageUrls(urls);
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/api/Library/downloadtexture/${texture.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${texture.name}.zip`;
            link.click();
        } catch (error) {
            console.error('Error downloading texture:', error);
        }
    };

    const handleImageClick = (url: string) => {
        setSelectedImageUrl(url);
    };

    return (
        <>
            <div className="texture-details-modal-backdrop" onClick={onClose}>
                <div className="texture-details-modal" onClick={(e) => e.stopPropagation()}>
                    <h3>{texture.name}</h3>
                    <div className="texture-image">
                        <img src={imageUrls['default']} alt="Default Map" className="big-image" />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: texture.description }} />
                    <div className="texture-maps-grid">
                        {textureType.mapConfiguration.map((mapName: string) => (
                            <div key={mapName} className="texture-map-item">
                                <img
                                    src={imageUrls[mapName]}
                                    alt={mapName}
                                    className="map-thumbnail"
                                    onClick={() => handleImageClick(imageUrls[mapName])}
                                />
                                <p>{mapName}</p>
                            </div>
                        ))}
                    </div>
                    <div className="texture-modal-actions">
                        <button onClick={handleDownload} className="download-btn">Download</button>
                        <button onClick={onClose} className="close-modal-btn">Close</button>
                    </div>
                </div>
            </div>

            {selectedImageUrl && (
                <ImageModal url={selectedImageUrl} onClose={() => setSelectedImageUrl(null)} />
            )}
        </>
    );
};

export default TextureDetailsModal;
