import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ItemCreationModal.css';


const ItemCreationModal: React.FC<any> = ({ template, subCategoryId, onSave, onCancel }) => {
  const [formData, setFormData] = useState<any>({});
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    if (template && typeof template.fields === 'string') {
      try {
        const parsedFieldsStrings = JSON.parse(template.fields);
        const parsedFields = parsedFieldsStrings.map((fieldString: string) => JSON.parse(fieldString));
        if (Array.isArray(parsedFields)) {
          setFields(parsedFields);
          const initialFormData: any = {};
          parsedFields.forEach((field: any) => {
            initialFormData[field.name] = '';
          });
          setFormData(initialFormData);
        }
      } catch (error) {
        console.error('Error parsing template fields:', error);
      }
    }
  }, [template]);

  const handleInputChange = (field: any, value: string) => {
    setFormData({ ...formData, [field.name]: value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    let imageUrl = '';
    if (imageFile) {
      const formData = new FormData();
      formData.append('image', imageFile);

      try {
        const response = await axios.post(`${localStorage.getItem("api")}/api/GameDatabase/uploadItemImage`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
        imageUrl = response.data.imageUrl;
      } catch (error) {
        console.error('Error uploading image:', error);
        return;
      }
    }

    const itemData = {
      ...formData,
      Image: imageUrl,
      SubCategoryID: subCategoryId,
    };

    try {
      await axios.post(`${localStorage.getItem("api")}/api/GameDatabase/newitem`, itemData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      onSave();
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  return (
    <div className="gamedb-item-modal">
      <div className="gamedb-item-modal-content">
        <h3>Create New Item</h3>
        {fields.length > 0 ? (
          fields.map((field: any) => (
            <div key={field.id} className="gamedb-item-field">
              <label>{field.name}</label>
              {field.type === 'text' || field.type === 'number' ? (
                <input
                  type={field.type === 'text' ? 'text' : 'number'}
                  onChange={(e) => handleInputChange(field, e.target.value)}
                />
              ) : field.type === 'image' ? (
                <input type="file" accept="image/*" onChange={handleFileChange} />
              ) : null}
            </div>
          ))
        ) : (
          <p>No fields available for this template.</p>
        )}
        <button onClick={handleSubmit}>Save Item</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ItemCreationModal;
