import React, { useState, useEffect } from 'react';
import './GameDatabase.css';
import SideMenu from './SideMenu';
import SubCategoryList from './SubCategoryList';

const GameDatabase: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);;

  const categories = ['Vehicles', 'VehicleWeapons', 'Weapons', 'Items', 'Others'];

  return (
    <div className="gamedb-container">
      <div className="gamedb-side-menu">
        <SideMenu
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />

      </div>
      {selectedCategory && (
        <div className="gamedb-content">
          <SubCategoryList
           category={selectedCategory}
          />
        </div>
      )}
    </div>
  );
};

export default GameDatabase;
