import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageModal from '../imagemodal/ImageModal';
import './Chat.css';

interface ChatProps {
  connection: any;
}

interface ChatRoom {
  id: number;
  name: string;
}

interface RoomChatMessage {
  id: number;
  userId: number;
  chatRoomId: number;
  message: string;
  dateTime: string;
}

interface User {
  id: number;
  name: string;
  image: string;
}

const apiBaseUrl = localStorage.getItem("api");

const Chat: React.FC<ChatProps> = ({ connection }) => {
  const [rooms, setRooms] = useState<ChatRoom[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<ChatRoom | null>(null);
  const [messages, setMessages] = useState<RoomChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);

  const userData = JSON.parse(localStorage.getItem('user') ?? '');
  const jwtToken = localStorage.getItem('jwt');

  useEffect(() => {
    const fetchRoomsAndUsers = async () => {
      try {
        const roomsResponse = await fetch(`${apiBaseUrl}/api/ChatRoom/rooms`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        const roomsData = await roomsResponse.json();
        setRooms(roomsData);

        const users = JSON.parse(localStorage.getItem('users') ?? '[]');
        setUsers(users);
      } catch (err) {
        console.error('Error fetching rooms and users:', err);
      }
    };

    fetchRoomsAndUsers();
  }, [jwtToken]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (document.querySelector('.chatroom-messages')) {
        const chatroomMessages = document.querySelector('.chatroom-messages') as HTMLElement;
        chatroomMessages.scrollTop = chatroomMessages.scrollHeight
      }
    }, 100);
  };

  const handleRoomSelect = async (room: ChatRoom) => {
    setSelectedRoom(room);
    try {
      const messagesResponse = await fetch(`${apiBaseUrl}/api/ChatRoom/messages/${room.id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      const messagesData = await messagesResponse.json();
      setMessages(messagesData);
      scrollToBottom();
      await connection.invoke("JoinRoom", room.id);
    } catch (err) {
      console.error('Error fetching room messages:', err);
    }
  };

  useEffect(() => {
    if (connection) {
      connection.on("ReceiveRoomMessage", (chatMessage: RoomChatMessage) => {
        if (chatMessage.userId !== userData.id) {
          setMessages((prevMessages) => [...prevMessages, chatMessage]);
          scrollToBottom();
        }
      });

      return () => {
        connection.off("ReceiveRoomMessage");
      };
    }
  }, [connection, userData.id]);

  const handleCreateRoom = async () => {
    const roomName = prompt("Enter room name:");
    if (roomName) {
      try {
        const newRoomResponse = await fetch(`${apiBaseUrl}/api/ChatRoom/create-room`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(roomName),
        });
        const newRoom = await newRoomResponse.json();
        setRooms((prevRooms) => [...prevRooms, newRoom]);
      } catch (err) {
        console.error('Error creating room:', err);
      }
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && previewImages.length === 0) return;

    const message: RoomChatMessage = {
      id: 0,
      userId: userData.id,
      chatRoomId: selectedRoom?.id ?? 0,
      message: newMessage,
      dateTime: new Date().toISOString(),
    };

    try {
      const sendMessageResponse = await fetch(`${apiBaseUrl}/api/ChatRoom/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(message),
      });
      const sentMessage = await sendMessageResponse.json();
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setNewMessage('');
      setPreviewImages([]);
      await connection.invoke("SendMessageToRoom", selectedRoom?.id, sentMessage.message, userData.id);
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = event.clipboardData.items;
    const files: File[] = [];
    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          // check if file is an image
          if (file.type.startsWith('image')) {
            files.push(file);
          } else {
            console.log('Only images are allowed');
            return;
          }
        }
      }
    }

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));
      formData.append('roomid', selectedRoom?.id.toString() ?? '0');

      try {
        const uploadResponse = await axios.post(`${apiBaseUrl}/api/ChatRoom/upload`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
        const uploadedFilesUrls = uploadResponse.data.map((fileName: string) => `${apiBaseUrl}/api/Image/${fileName}`);
        setPreviewImages((prevImages) => [...prevImages, ...uploadedFilesUrls]);
        setNewMessage((prevMessage) => prevMessage + uploadedFilesUrls.map((url: string) => `<img width="500" src="${url}" alt="image" class="chat-thumbnail" />`).join(' '));
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleImageClick = (url: string) => {
    setEnlargedImage(url);
  };

  const getUserDetails = (userId: number) => {
    return users.find(user => user.id === userId) || { name: "Unknown", image: "default-avatar-url" };
  };

  return (
    <div className="chatroom-container">
      <div className="rooms-sidebar">
        <h3>Rooms</h3>
        <ul>
          {rooms.map((room) => (
            <li key={room.id} onClick={() => handleRoomSelect(room)}>
              {room.name}
            </li>
          ))}
        </ul>
        <button onClick={handleCreateRoom}>+</button>
      </div>
      <div className="chatroom-area">
        {selectedRoom ? (
          <>
            <div className="chatroom-header">
              {selectedRoom.name}
            </div>
            <div className="chatroom-messages">
              {messages.map((msg) => {
                const userDetails = getUserDetails(msg.userId);
                return (
                  <div key={msg.id} className="chatroom-message">
                    <div className="message-info">
                      <img src={userDetails.image} alt="User" className="user-avatar" />
                      <span className="user-name">{userDetails.name}</span>
                      <span className="message-time">{new Date(msg.dateTime).toLocaleTimeString()}</span>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: msg.message.replace(/\n/g, '<br />') }} onClick={(e) => {
                      const target = e.target as HTMLImageElement;
                      if (target.tagName === 'IMG') {
                        handleImageClick(target.src);
                      }
                    }}></p>
                  </div>
                );
              })}
            </div>
            <div className="chatroom-input-area">
              <div className="image-previews">
                {previewImages.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt="preview"
                    className="preview-image"
                    onClick={() => handleImageClick(url)}
                  />
                ))}
              </div>
              <textarea
                placeholder="Type a message"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onPaste={handlePaste}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  } else if (e.key === 'Enter' && e.shiftKey) {
                    e.stopPropagation();
                  }
                }}
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <div className="select-room-prompt">Please select a room</div>
        )}
      </div>
      {enlargedImage && <ImageModal url={enlargedImage} onClose={() => setEnlargedImage(null)} />}
    </div>
  );
};

export default Chat;
