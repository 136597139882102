import React from 'react';
import './GameDatabase.css';

interface SideMenuProps {
  categories: string[];
  selectedCategory: string | null;
  onSelectCategory: (category: string) => void; 
}

const SideMenu: React.FC<SideMenuProps> = ({ categories, selectedCategory, onSelectCategory }) => {
  return (
      <><div>
        <h2>Categories</h2>
        <ul>
          {categories.map((category) => (
            <li key={category}>
              <button
                className={selectedCategory === category ? 'active' : ''}
                onClick={() => onSelectCategory(category)}
              >
                {category}
              </button>
            </li>
          ))}
        </ul>
      </div></>
  );
};

export default SideMenu;
