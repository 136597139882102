import React, { useState, useEffect } from 'react';
import './Bord.css';
import JobModal from './JobModal';
import { FaPlusCircle } from "react-icons/fa";

interface Job {
  id: number;
  jobBordId: number;
  name: string;
  text: string;
  data: string;
  assignedUsers: string;
  date: string;
  status: string;
}

interface JobBord {
  id: number;
  name: string;
  description: string;
  position: number;
  date: string;
  roadmapId: number;
}

interface BordProps {
  jobBord: JobBord;
}

interface User {
  id: number;
  name: string;
  image: string;
}

const Bord: React.FC<BordProps> = ({ jobBord }) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const users = JSON.parse(localStorage.getItem('users') ?? '[]');

  useEffect(() => {
    const fetchJobs = async () => {
      const apiBaseUrl = localStorage.getItem('api');
      const data = await fetchFromApi(`${apiBaseUrl}/api/Data/getjobs/${jobBord.id}`);
      if (data) {
        setJobs(Array.isArray(data) ? data : []);
      }
    };

    fetchJobs();
  }, [jobBord.id]);

  const fetchFromApi = async (url: string, method: string = 'GET', body: any = null) => {
    const token = localStorage.getItem('jwt');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        console.error(`Failed to fetch ${url}:`, response.statusText);
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      return null;
    }
  };

  const handleOpenJobModal = (job?: Job) => {
    setSelectedJob(job || null);
    setIsJobModalOpen(true);
  };

  const handleCloseJobModal = () => {
    setIsJobModalOpen(false);
  };

  const handleCreateOrUpdateJob = async (newJob: Job) => {
    const apiBaseUrl = localStorage.getItem('api');
    const method = newJob.id > 0 ? 'PUT' : 'POST';
    const url = newJob.id > 0 ? `${apiBaseUrl}/api/Data/updatejob/${newJob.id}` : `${apiBaseUrl}/api/Data/newjob`;

    newJob.jobBordId = jobBord.id;
    console.log('Creating or updating job:', newJob);
    const createdOrUpdatedJob = await fetchFromApi(url, method, newJob);

    if (createdOrUpdatedJob) {
      setJobs((prevJobs) => {
        const otherJobs = prevJobs.filter((job) => job.id !== createdOrUpdatedJob.id);
        return [...otherJobs, createdOrUpdatedJob];
      });
    }

    setIsJobModalOpen(false);
  };

  const handleDeleteJob = async (job: Job) => {
    const apiBaseUrl = localStorage.getItem('api');
    await fetchFromApi(`${apiBaseUrl}/api/Data/deletejob/${job.id}`, 'POST');
    setJobs((prevJobs) => prevJobs.filter((j) => j.id !== job.id));
    setIsJobModalOpen(false);
  };

  const onDragStart = (e: React.DragEvent, jobId: number) => {
    e.dataTransfer.setData("jobId", jobId.toString());
  };

  const onDrop = async (e: React.DragEvent, newStatus: string) => {
    const jobId = parseInt(e.dataTransfer.getData("jobId"));
    const jobToUpdate = jobs.find(job => job.id === jobId);

    if (jobToUpdate && jobToUpdate.status !== newStatus) {
      const updatedJob = { ...jobToUpdate, status: newStatus };
      await handleCreateOrUpdateJob(updatedJob);
    }
  };

  const renderColumn = (status: string, color: string) => (
    <div 
      className="job-column"
      onDrop={(e) => onDrop(e, status)}
      onDragOver={(e) => e.preventDefault()}
    >
      <h3 style={{ backgroundColor: color }}>{status} {"(" + jobs.filter((job) => job.status === status).length + ")"}</h3>
      <div className="job-list">
        {jobs
          .filter((job) => job.status === status)
          .map((job) => {
            const textObj = JSON.parse(job.text || '{}');
            let assignedUsers = JSON.parse(job.assignedUsers || '[]');
  
            // Ensure that assignedUsers is an array
            if (!Array.isArray(assignedUsers)) {
              assignedUsers = [];
            }
  
            console.log('Assigned users:', assignedUsers);
            return (
              <div 
                key={job.id} 
                className="job-card" 
                onClick={() => handleOpenJobModal(job)}
                draggable
                onDragStart={(e) => onDragStart(e, job.id)}
              >
                <p className="job-title"> {job.name}</p>
                <p className="job-description"> {textObj.description}</p>
                <div className="job-footer">
                  <div className="assigned-users">
                    {assignedUsers.map((userId: string) => {
                      const user = users.find((u: User) => u.id === parseInt(userId));
                      return user ? ( 
                        <img key={user.id} src={user.image} alt={user.name} className="user-avatar" />
                      ) : null;
                    })}
                  </div>
                  <div className="job-time">{new Date(job.date).toLocaleDateString()}</div>
                </div>
              </div>
            );
          })}
        <div className="add-job-button" onClick={() => handleOpenJobModal()}>
          <FaPlusCircle size={32} />
        </div>
      </div>
    </div>
  );
  

  return (
    <div className="bord-container">
      <h4 style={{textAlign: "center"}}>{jobBord.name}</h4>
      <div className="job-columns">
        {renderColumn('TODO', '#15e6ca')}        {/* Red */}
        {renderColumn('IN-WORK', '#15e6ca')}     {/* Orange */}
        {renderColumn('COMPLETED', '#15e6ca')}   {/* Green */}
      </div>

      {isJobModalOpen && (
        <JobModal
        job={selectedJob}
        onClose={handleCloseJobModal}
        onSave={handleCreateOrUpdateJob}
        onDelete={handleDeleteJob}
        roadmapId={jobBord.roadmapId}
        jobBordId={jobBord.id}
        />
      )}
    </div>
  );
};

export default Bord;
