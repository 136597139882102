import React, { useEffect, useState } from 'react';
import './SubCategoryList.css';
import axios from 'axios';
import { FaSearch, FaPlus } from 'react-icons/fa';
import TemplateFieldBuilder, { TemplateField } from './TemplateFieldBuilder';
import ItemCreationModal from './ItemCreationModal';

const SubCategoryList: React.FC<any> = ({ category }) => {
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number | null>(null);
  const [items, setItems] = useState<any[]>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);
  const [showCategoryBuilder, setShowCategoryBuilder] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  useEffect(() => {
    fetchSubCategories(category);
    fetchTemplates();
  }, [category]);

  useEffect(() => {
    if (selectedSubCategoryId && selectedSubCategoryId !== 0) {
      // find template for selected subcategory
      const templateId = subCategories.find(sc => sc.id === selectedSubCategoryId)?.templateID;
      const template = templates.find(t => t.id === templateId);
      setSelectedTemplate(template);
      fetchItems(selectedSubCategoryId);
    }
  }, [selectedSubCategoryId]);

  useEffect(() => {
    filterItems();
  }, [searchQuery, selectedCountry, items]);

  const fetchSubCategories = async (category: string) => {
    try {
      const response = await axios.get(`${localStorage.getItem("api")}/api/GameDatabase/subcategories`, {
        params: { category },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      setSubCategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${localStorage.getItem("api")}/api/GameDatabase/templates`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const fetchItems = async (subCategoryId: number) => {
    try {
      const response = await axios.get(`${localStorage.getItem("api")}/api/GameDatabase/items`, {
        params: { subCategoryId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      setItems(response.data);
      setFilteredItems(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const filterItems = () => {
    let filtered = items;
    if (searchQuery) {
      filtered = filtered.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    if (selectedCountry) {
      filtered = filtered.filter(item => item.country === selectedCountry);
    }
    setFilteredItems(filtered);
  };

  const handleCreateSubCategory = async () => {
    if (!newSubCategoryName || !selectedTemplate) {
      alert("Please enter a subcategory name and select a template.");
      return;
    }

    try {
      const response = await axios.post(`${localStorage.getItem("api")}/api/GameDatabase/newsubcategory`, {
        name: newSubCategoryName,
        templateId: selectedTemplate,
        category: category,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      setSubCategories([...subCategories, response.data]);
      setShowCategoryBuilder(false);
      setNewSubCategoryName('');
      setSelectedTemplate('');

    } catch (error) {
      console.error('Error creating subcategory:', error);
    }
  };

  const handleCreateItem = () => {
    setShowItemModal(true);
  };

  const addTemplate = (id: number, name: string, fields: TemplateField[]) => {
    const template = { id, name, fields };
    setTemplates([...templates, template]);
  }

  const handleSaveTemplate = async (templateName: string, templateFields: TemplateField[]) => {
    try {
      const serializedFields = templateFields.map(field => JSON.stringify(field));

      const response = await axios.post(`${localStorage.getItem("api")}/api/GameDatabase/newtemplate`, {
        name: templateName,
        fields: serializedFields,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      addTemplate(response.data, templateName, templateFields);
      setShowTemplateBuilder(false);

    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  return (
    <div className="gamedb-subcategory-list-container">
      <div className="gamedb-subcategory-list">
        {subCategories.map(subCategory => (
          <div
            key={subCategory.id}
            className={`gamedb-subcategory-item ${subCategory.id === selectedSubCategoryId ? 'selected' : ''}`}
            onClick={() => setSelectedSubCategoryId(subCategory.id)}
          >
            <h3>{subCategory.name}</h3>
          </div>
        ))}
      </div>

      {selectedSubCategoryId && (
        <div className="gamedb-items-container">
          <div className="gamedb-items-toolbar">
            <div className="gamedb-toolbar-search">
              <FaSearch />
              <input
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="gamedb-toolbar-country">
              <select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value="">All Countries</option>
                <option value="USA">USA</option>
                <option value="RUS">Russia</option>
                <option value="CHN">China</option>
                {/* Add more countries as needed */}
              </select>
            </div>
            <button className="gamedb-create-item-btn" onClick={handleCreateItem}>
              <FaPlus /> Create Item
            </button>
          </div>

          <div className="gamedb-items-list">
            {filteredItems.map(item => (
              <div key={item.id} className="gamedb-item-card">
                <img src={item.imageUrl} alt={item.name} />
                <h4>{item.name}</h4>
                <p>{item.country}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      <button className="gamedb-create-template-btn" onClick={() => setShowTemplateBuilder(!showTemplateBuilder)}>
        <FaPlus /> Create Template
      </button>
      <button className="gamedb-create-subcategory-btn" onClick={() => setShowCategoryBuilder(!showCategoryBuilder)}>
        <FaPlus /> Create Subcategory
      </button>

      {showTemplateBuilder && (
        <TemplateFieldBuilder
          template={[]}
          onSave={handleSaveTemplate}
          onCancel={() => setShowTemplateBuilder(false)}
        />
      )}

      {showCategoryBuilder && (
        <div className="gamedb-subcategory-modal">
          <div className="gamedb-subcategory-modal-content">
            <h3>Create New Subcategory</h3>
            <input
              type="text"
              placeholder="Subcategory Name"
              value={newSubCategoryName}
              onChange={(e) => setNewSubCategoryName(e.target.value)}
            />
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
            >
              <option value="">Select Template</option>
              {templates.map((template: any) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
            <button onClick={handleCreateSubCategory}>Create Subcategory</button>
            <button onClick={() => setShowCategoryBuilder(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showItemModal && selectedTemplate && (
        <ItemCreationModal
          template={selectedTemplate}
          subCategoryId={selectedSubCategoryId}
          onSave={() => {
            fetchItems(selectedSubCategoryId!);
            setShowItemModal(false);
          }}
          onCancel={() => setShowItemModal(false)}
        />
      )}
    </div>
  );
};

export default SubCategoryList;
