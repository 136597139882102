import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaThList,
  FaTh,
  FaFolder,
  FaFolderOpen,
  FaDownload,
} from "react-icons/fa";
import "./FileBrowser.css";

interface UploadedFile {
  id: number;
  fileName: string;
  filePath: string;
  fileType: string;
  uploadDate: string;
}

interface Folder {
  name: string;
  type: "roadmap" | "jobbord" | "file";
  id: number;
  children?: Folder[];
  isOpen?: boolean;
}

const FileBrowser: React.FC = () => {
  const [folders, setFolders] = useState<Folder[]>([]);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [currentPath, setCurrentPath] = useState<Folder[]>([]);
  const [selectedItem, setSelectedItem] = useState<UploadedFile | null>(null);
  const [viewType, setViewType] = useState<"list" | "grid">("list");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const apiBaseUrl = localStorage.getItem("api");

  useEffect(() => {
    fetchFoldersAndFiles();
  }, []);

  const fetchFoldersAndFiles = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/Data/getroadmaps`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const folders = response.data.map((r: Folder) => ({
        name: r.name,
        type: "roadmap",
        id: r.id,
        children: [],
        isOpen: false,
      }));
      setFolders(folders);
      setFiles([]);
      setCurrentPath([]);
    } catch (error) {
      console.error("Error fetching roadmaps:", error);
    }
  };

  const handleFolderClick = async (folder: Folder) => {
    // Open the folder and fetch children if needed
    let updatedFolders = toggleFolderState(folders, folder.id, true);

    if (folder.type === "roadmap" && folder.children?.length === 0) {
      if (folder.isOpen) {
        // Close the folder if it's already open
        const updatedFolders = toggleFolderState(folders, folder.id, false);
        setFolders(updatedFolders);
        return;
      }
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/Data/getjobbords/${folder.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        const children = response.data.map((jb: any) => ({
          name: jb.name,
          type: "jobbord",
          id: jb.id,
          children: [],
          isOpen: false,
        }));

        updatedFolders = addChildrenToFolder(updatedFolders, folder.id, children);
        setFolders(updatedFolders);
      } catch (error) {
        console.error("Error fetching job boards:", error);
      }
    } else if (folder.type === "jobbord") {
      // loop through other jobbords in the same roadmap and close them
      for (let i = 0; i < updatedFolders.length; i++) {
        if (updatedFolders[i].type === "roadmap") {
          updatedFolders[i].children = updatedFolders[i].children?.map((jb) => ({
            ...jb,
            isOpen: jb.id === folder.id,
          }));
        }
      }
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/Upload/getbordfiles/${folder.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        const files = response.data;

        setFiles(files);
        setCurrentPath((prevPath) => [
          ...prevPath.filter((f) => f.type !== "jobbord"),
          folder,
        ]);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    }

    setFolders(updatedFolders);
    setSelectedItem(null); // Hide the right panel when switching folders
  };

  const toggleFolderState = (folders: Folder[], folderId: number, isOpen: boolean): Folder[] => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, isOpen };
      }
      if (folder.children && folder.children.length > 0) {
        return { ...folder, children: toggleFolderState(folder.children, folderId, isOpen) };
      }
      return folder;
    });
  };

  const addChildrenToFolder = (folders: Folder[], folderId: number, children: Folder[]): Folder[] => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, children };
      }
      if (folder.children && folder.children.length > 0) {
        return { ...folder, children: addChildrenToFolder(folder.children, folderId, children) };
      }
      return folder;
    });
  };

  const handleFileClick = (file: UploadedFile) => {
    setSelectedItem(file);
  };

  const handleDownload = async (id: number) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/api/Upload/download/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const filename = response.headers["content-disposition"]
        ? response.headers["content-disposition"].split("filename=")[1]
        : files.find((file) => file.id === id)?.fileName || "file";

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const filteredFiles = files.filter((file) =>
    file.fileName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderFolderIcon = (folder: Folder) => {
    return folder.isOpen ? <FaFolderOpen /> : <FaFolder />;
  };

  const renderFileIcon = (file: UploadedFile) => {
    if (file.fileType.startsWith("image/") || file.fileType.endsWith(".jpg") || file.fileType.endsWith(".png")) {
      return (
        <img
          src={`${apiBaseUrl}/api/Image/${file.fileName}`}
          alt={file.fileName}
          className="file-icon"
        />
      );
    } else {
      return <span className="icon-file"></span>;
    }
  };

  const renderTree = (folders: Folder[]) => {
    return folders.map((folder) => (
      <li key={folder.id}>
        <div className="folder-item" onClick={() => handleFolderClick(folder)}>
          {renderFolderIcon(folder)} {folder.name}
        </div>
        {folder.isOpen && folder.children && folder.children.length > 0 && (
          <ul>{renderTree(folder.children)}</ul>
        )}
      </li>
    ));
  };

  return (
    <div className="file-browser">
      <div className="file-toolbar">
        <div className="view-toggle">
          <button onClick={() => setViewType(viewType === "list" ? "grid" : "list")}>
            {viewType === "list" ? <FaTh /> : <FaThList />}
          </button>
        </div>
        <div className="search-items">
          <span className="icon-search"></span>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="file-content">
        <div className="left-panel">
          <ul className="folder-tree">
            {renderTree(folders)}
          </ul>
        </div>
        <div className="middle-panel">
          <h3>{currentPath.map((folder) => folder.name).join(" / ")}</h3>
          <div className={`file-list ${viewType}`}>
            {filteredFiles.map((file) => (
              <div
                key={file.id}
                className={`file-item ${selectedItem?.id === file.id ? 'selected' : ''}`}
                onClick={() => handleFileClick(file)}
                title={file.fileName} // Full name on hover
              >
                {renderFileIcon(file)}
                <p className="file-name">
                  {file.fileName.length > 20
                    ? `${file.fileName.substring(0, 20)}...`
                    : file.fileName}
                </p>
              </div>
            ))}
          </div>
        </div>
        {selectedItem && (
          <div className="right-panel">
            <div className="file-details">
              <div className="details-header">
                <span>{selectedItem.fileName.length > 20 ? `${selectedItem.fileName.substring(0, 20)}...` : selectedItem.fileName}</span>
                <FaDownload
                  className="download-icon"
                  onClick={() => handleDownload(selectedItem.id)}
                />
              </div>
              {(selectedItem.fileType.startsWith("image/") || selectedItem.fileType.endsWith(".jpg") || selectedItem.fileType.endsWith(".png")) && (
                <img
                  src={`${apiBaseUrl}/api/Image/${selectedItem.fileName}`}
                  alt={selectedItem.fileName}
                  className="preview-image"
                />
              )}
              <div className="file-info">
                <p>Type: {selectedItem.fileType}</p>
                <p>Size: {(selectedItem.filePath.length / 256).toFixed(1)} KB</p>
                <p>Uploaded on: {selectedItem.uploadDate}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileBrowser;
