import React from 'react';
import './Home.css';  // We'll define styles here
import JoinUsForm from './JoinUsForm';  // Import the JoinUsForm component

const Home: React.FC = () => {
    const [activeSection, setActiveSection] = React.useState<string>('home');

    const renderContent = () => {
        switch (activeSection) {
            case 'joinus':
                return <JoinUsForm />;  // Render JoinUsForm component
            default:
                return (
                    <div className="text-content">
                        <div className="text-area">
                            <h1>The Rise of Reflex Military DEV</h1>
                            <p>Vítejte na strankách o vývoji našeho serveru, které primárně slouží lidem v našem týmu </p>
                            <p>a vám donáší novinky o vývoji serveru a možnosti se na něm podílet!</p>
                        </div>
                        <div className="cta-area">
                            <div className="cta-box">
                                <p>Právě probíhá přijmání nových developerů.</p>
                                <button className="main-button" onClick={() => setActiveSection('joinus')}>Připoj se k nám !</button>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="home-container">
            <div className="home-top-bar">
                <div className="menu-links">
                    <a className="menu-link" onClick={() => setActiveSection('home')}>Domů</a>
                    <a href="https://discord.gg/BQWC5KQ3" className="menu-link">Discord</a>
                    {/* <a href="/devinfo" className="menu-link">Plán vývoje</a> */}
                    <a className="menu-link" onClick={() => setActiveSection('joinus')}>Připoj se!</a>
                </div>
                <a href="/login" className="admin-button">Administrace</a>
            </div>
            <div className="main-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Home;
