import React from 'react';
import './ImageModal.css';

interface ImageModalProps {
  url: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ url, onClose }) => {

    const downloadImage = async () => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image';
        link.click();
    }

  return (
    <div className="image-modal-backdrop" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={url} alt="Enlarged" className="image-modal-img" />
      </div>
      <button className="download-button" onClick={downloadImage}>Download</button>
    </div>
  );
};

export default ImageModal;