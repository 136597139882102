import React, { useState } from 'react';
import axios from 'axios';
import './ImageResize.css';
import { FaPlusCircle } from "react-icons/fa";

const ddsFormats = [
  { label: "DXT1", value: "DXT1" },
  { label: "DXT3", value: "DXT3" },
  { label: "DXT5", value: "DXT5" },
  { label: "ATI1", value: "ATI1" },
  { label: "ATI2", value: "ATI2" },
  { label: "BC7", value: "BC7" },
  { label: "A8R8G8B8", value: "A8R8G8B8" },
  { label: "X8R8G8B8", value: "X8R8G8B8" },
  { label: "A1R5G5B5", value: "A1R5G5B5" },
  { label: "A8", value: "A8" },
  { label: "A8B8G8R8", value: "A8B8G8R8" },
  { label: "L8", value: "L8" },
];

const scaleOptions = [
  { label: "25%", value: 25 },
  { label: "50%", value: 50 },
  { label: "100%", value: 100 }
];

const apiBaseUrl = localStorage.getItem("api");

const ImageResize: React.FC = () => {
  const [images, setImages] = useState<any[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileList = Array.from(e.target.files);
      const updatedImages = fileList.map(file => ({
        file,
        scale: 100,
        format: "DXT5",
        convertToDDS: true
      }));
      setImages(prevImages => [...prevImages, ...updatedImages]);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files) {
      const fileList = Array.from(e.dataTransfer.files);
      const updatedImages = fileList.map(file => ({
        file,
        scale: 100,
        format: "DXT5",
        convertToDDS: true
      }));
      setImages(prevImages => [...prevImages, ...updatedImages]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleScaleChange = (index: number, scale: number) => {
    const updatedImages = [...images];
    updatedImages[index].scale = scale;
    setImages(updatedImages);
  };

  const handleFormatChange = (index: number, format: string) => {
    const updatedImages = [...images];
    updatedImages[index].format = format;
    setImages(updatedImages);
  };

  const handleConvertToDDSChange = (index: number, convert: boolean) => {
    const updatedImages = [...images];
    updatedImages[index].convertToDDS = convert;
    setImages(updatedImages);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleClearAll = () => {
    setImages([]);
  };
    const handleProcessImages = async () => {
        try {
            const formData = new FormData();

            images.forEach((img, index) => {
                const uniqueKey = img.file.name.split('.').slice(0, -1).join('.');
                formData.append('files', img.file);
                formData.append(`scale_${uniqueKey}`, img.scale.toString());
                if (img.convertToDDS) {
                    formData.append(`ddsFormat_${uniqueKey}`, img.format);
                }
            });

            const response = await axios.post(`${apiBaseUrl}/api/Library/processImages`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
                responseType: 'blob', // Important for handling binary data
            });

            // Create a URL for the file blob and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'processed_images.zip'); // Define the filename
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error processing images:', error);
        }
    };


  return (
    <div className="image-resize-container">
      <button className="clear-all-button" onClick={handleClearAll}>
        Clear All
      </button>
      <div
        className="image-upload-area"
        onClick={() => document.getElementById('image-upload-input')?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <FaPlusCircle size={50} />
        <p>Drag & Drop Images Here or Click to Upload</p>
        <input
          id="image-upload-input"
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>

      <div className="image-list">
        {images.map((img, index) => (
          <div key={index} className="image-list-item">
            <img src={URL.createObjectURL(img.file)} alt="uploaded" />
            <div className="image-options">
              <label>
                Scale:
                <select
                  className="scale-select"
                  value={img.scale}
                  onChange={(e) => handleScaleChange(index, parseInt(e.target.value))}
                >
                  {scaleOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={img.convertToDDS}
                  onChange={(e) => handleConvertToDDSChange(index, e.target.checked)}
                />
                Convert to DDS
              </label>
              {img.convertToDDS && (
                <select
                  className="dds-format-select"
                  value={img.format}
                  onChange={(e) => handleFormatChange(index, e.target.value)}
                >
                  {ddsFormats.map(format => (
                    <option key={format.value} value={format.value}>{format.label}</option>
                  ))}
                </select>
              )}
            </div>
            <button
              className="delete-image-button"
              onClick={() => handleDeleteImage(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>

      {images.length > 0 && (
        <button className="process-images-btn" onClick={handleProcessImages}>
          Process Images
        </button>
      )}
    </div>
  );
};

export default ImageResize;
