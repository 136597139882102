import React, { useRef, useEffect, useState } from 'react';
import './BasicTextEditor.css';
import {
  FaBold, FaItalic, FaUnderline, FaListUl, FaListOl,
  FaAlignLeft, FaAlignCenter, FaAlignRight, FaHeading, FaLink, FaImage, FaExpand, FaPalette, FaTextHeight
} from 'react-icons/fa';
import { SketchPicker, ColorResult } from 'react-color';

interface BasicTextEditorProps {
  getContentRef: React.MutableRefObject<() => string>; // Pass a ref to get content function
  initialContent?: string; // Optional prop for initial content
}

const BasicTextEditor: React.FC<BasicTextEditorProps> = ({ getContentRef, initialContent }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState('#000000'); // Default text color
  const colorPickerRef = useRef<HTMLDivElement>(null);

  // Set the getContent function to the ref passed from parent
  getContentRef.current = () => {
    return editorRef.current?.innerHTML || '';
  };

  // Set initial content on mount
  useEffect(() => {
    if (initialContent && editorRef.current) {
      editorRef.current.innerHTML = initialContent;
    }
  }, [initialContent]);

  // Close color picker when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
        setShowColorPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const execCommand = (command: string, value: string | null = null) => {
    document.execCommand('styleWithCSS', false, 'true');
    document.execCommand(command, false, value ?? undefined);
    editorRef.current?.focus();  // Re-focus the editor after executing a command
  };

  const handleInsertImage = () => {
    const url = prompt('Enter the image URL:');
    if (url) {
      execCommand('insertImage', url);
    }
  };

  const handleLink = () => {
    const url = prompt('Enter the URL:');
    if (url) {
      execCommand('createLink', url);
    }
  };

  const handleColorChange = (color: ColorResult) => {
    setColor(color.hex);
    execCommand('foreColor', color.hex);
    setShowColorPicker(false);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        execCommand('insertLineBreak');
      } else {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={`text-editor ${isFullscreen ? 'fullscreen' : ''}`}>
      <div className="toolbar">
        <div className="toolbar-group">
          <FaHeading className="toolbar-icon" />
          <select onChange={(e) => execCommand('formatBlock', e.target.value)} defaultValue="p" className="toolbar-select">
            <option value="p">P</option>
            <option value="h1">H1</option>
            <option value="h2">H2</option>
            <option value="h3">H3</option>
            <option value="h4">H4</option>
            <option value="h5">H5</option>
            <option value="h6">H6</option>
          </select>
        </div>
        <div className="toolbar-group">
          <FaTextHeight className="toolbar-icon" />
          <select onChange={(e) => execCommand('fontSize', e.target.value)} defaultValue="3" className="toolbar-select">
            {Array.from({ length: 67 }, (_, i) => i + 6).map(size => (
              <option key={size} value={size}>
                {size}px
              </option>
            ))}
          </select>
        </div>

        <button onClick={() => execCommand('bold')}>
          <FaBold />
        </button>
        <button onClick={() => execCommand('italic')}>
          <FaItalic />
        </button>
        <button onClick={() => execCommand('underline')}>
          <FaUnderline />
        </button>

        <button onClick={() => execCommand('insertUnorderedList')}>
          <FaListUl />
        </button>
        <button onClick={() => execCommand('insertOrderedList')}>
          <FaListOl />
        </button>

        <button onClick={() => execCommand('justifyLeft')}>
          <FaAlignLeft />
        </button>
        <button onClick={() => execCommand('justifyCenter')}>
          <FaAlignCenter />
        </button>
        <button onClick={() => execCommand('justifyRight')}>
          <FaAlignRight />
        </button>

        <button onClick={handleLink}>
          <FaLink /> Link
        </button>
        <button onClick={handleInsertImage}>
          <FaImage /> Image
        </button>
        <button onClick={toggleFullscreen}>
          <FaExpand /> {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
        </button>
      </div>
      <div
        ref={editorRef}
        className="editor"
        contentEditable={true}
        suppressContentEditableWarning={true}
        onKeyDown={handleKeyDown}
      ></div>
    </div>
  );
};

export default BasicTextEditor;
