import React, { useState } from 'react';
import { FaHome, FaTasks, FaWpforms, FaRegFolderOpen, FaCar } from 'react-icons/fa';
import { IoChatboxEllipsesSharp } from "react-icons/io5";
import { MdOutlineTexture } from "react-icons/md";
import { MdPhotoSizeSelectActual } from "react-icons/md";
import './Sidebar.css';

interface SidebarProps {
  setActiveSection: (section: any) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ setActiveSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <ul>
        <li onClick={() => setActiveSection('dashboard')}>
          <FaHome /><span className="menu-text">{isOpen && 'Dashboard'}</span>
        </li>
        <li onClick={() => setActiveSection('roadmap')}>
          <FaTasks /><span className="menu-text">{isOpen && 'Roadmap'}</span>
        </li>
        <li onClick={() => setActiveSection('filebrowser')}>
          <FaRegFolderOpen  /><span className="menu-text">{isOpen && 'File Browser'}</span>
        </li>
        <li onClick={() => setActiveSection('chat')}>
          <IoChatboxEllipsesSharp  /><span className="menu-text">{isOpen && 'Chat'}</span>
        </li>
        <li onClick={() => setActiveSection('library')}>
          <MdOutlineTexture  /><span className="menu-text">{isOpen && 'Library'}</span>
        </li>
        <li onClick={() => setActiveSection('imageresize')}>
          <MdPhotoSizeSelectActual /><span className="menu-text">{isOpen && 'Image Resize'}</span>
        </li>
        <li onClick={() => setActiveSection('formreader')}>
          <FaWpforms /><span className="menu-text">{isOpen && 'Form Reader'}</span>
        </li>
        {/* <li onClick={() => setActiveSection('gamedb')}>
          <FaCar /><span className="menu-text">{isOpen && 'Game Database'}</span>
        </li> */}

      </ul>
    </div>
  );
};

export default Sidebar;
