import React, { useState, useEffect } from 'react';
import CreateTextureModal from './CreateTextureModal';
import TextureDetailsModal from './TextureDetailsModal'; // Import the new component
import './TextureLibrary.css';

const apiBaseUrl = localStorage.getItem("api");

interface TextureType {
    id: number;
    name: string;
    mapConfiguration: string[];
}

const TextureLibrary: React.FC = () => {
    const [selectedFolder, setSelectedFolder] = useState<{ id: number, name: string } | null>(null);
    const [textureTypes, setTextureTypes] = useState<TextureType[]>([]);
    const [textures, setTextures] = useState<any[]>([]);
    const [folders, setFolders] = useState<any[]>([]);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [isTextureModalOpen, setIsTextureModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedTexture, setSelectedTexture] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [newFolderName, setNewFolderName] = useState<string>('');

    useEffect(() => {
        fetchFolders();
        fetchTextureTypes();
    }, []);

    const fetchTextureTypes = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/Library/gettexturetypes`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
            });
            const data = await response.json();
            const parsedData = data.map((type: any) => ({
                ...type,
                mapConfiguration: Array.isArray(type.mapConfiguration)
                    ? type.mapConfiguration
                    : JSON.parse(type.mapConfiguration || "[]"),
            }));
            setTextureTypes(parsedData);
        } catch (error) {
            console.error('Error fetching texture types:', error);
        }
    };

    const fetchFolders = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/Library/getfolders`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
            });
            const data = await response.json();
            setFolders(data);
        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const handleFolderClick = (folder: { id: number, name: string }) => {
        setSelectedFolder(folder);
        fetchTextures(folder.id);
    };

    const fetchTextures = async (folderId: number) => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/Library/getfolder/${folderId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
            });
            const data = await response.json();
            setTextures(data);
        } catch (error) {
            console.error('Error fetching textures:', error);
        }
    };

    const handleTextureClick = (texture: any) => {
        setSelectedTexture(texture);
        setIsDetailsModalOpen(true);
    };

    const handleCreateFolder = async () => {
        if (newFolderName.trim() === '') {
            alert('Folder name cannot be empty.');
            return;
        }

        try {
            const response = await fetch(`${apiBaseUrl}/api/Library/createfolder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
                body: JSON.stringify({ name: newFolderName }),
            });

            if (response.ok) {
                setIsFolderModalOpen(false);
                setNewFolderName('');
                fetchFolders();
            } else {
                console.error('Failed to create folder.');
            }
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleOpenFolderModal = () => {
        setIsFolderModalOpen(true);
    };

    const handleOpenTextureModal = () => {
        setIsTextureModalOpen(true);
    };

    const handleCloseTextureModal = () => {
        setIsTextureModalOpen(false);
        if (selectedFolder) {
            fetchTextures(selectedFolder.id);
        }
    };

    return (
        <div className="library-container">
            <div className="library-sidebar">
                <button onClick={handleOpenFolderModal} className="library-create-folder-btn">+</button>
                <div className="library-folder-list">
                    {folders.map((folder: any) => (
                        <div
                            key={folder.id}
                            className={`library-folder-item ${selectedFolder?.id === folder.id ? 'active' : ''}`}
                            onClick={() => handleFolderClick(folder)}
                        >
                            {folder.name}
                        </div>
                    ))}
                </div>
            </div>
            <div className="library-texture-display">
                <div className="library-search-and-create">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="library-search-bar"
                    />
                    {selectedFolder && (
                        <button onClick={handleOpenTextureModal} className="library-create-texture-btn">
                            + Create Texture
                        </button>
                    )}
                </div>
                <div className="library-texture-grid">
                    {textures.map((texture, index) => (
                        <div key={index} className="library-texture-item" onClick={() => handleTextureClick(texture)}>
                            <img
                                src={`${apiBaseUrl}/api/Library/${texture.name}/default`}
                                alt={texture.name}
                                className="library-texture-thumbnail"
                            />
                            <p>{texture.name}</p>
                            <p>{textureTypes.find((type) => type.id === texture.textureTypeId)?.name}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Folder Creation Modal */}
            {isFolderModalOpen && (
                <div className="library-modal-backdrop">
                    <div className="library-modal">
                        <h3>Create New Folder</h3>
                        <input
                            type="text"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            placeholder="Folder Name"
                        />
                        <button onClick={handleCreateFolder} className="library-modal-create-btn">Create</button>
                        <button onClick={() => setIsFolderModalOpen(false)} className="library-close-modal-btn">X</button>
                    </div>
                </div>
            )}

            {/* Texture Creation Modal */}
            {isTextureModalOpen && selectedFolder && (
                <CreateTextureModal
                    onClose={handleCloseTextureModal}
                    onSave={handleCloseTextureModal}
                    folderId={selectedFolder.id}
                />
            )}

            {/* Texture Details Modal */}
            {isDetailsModalOpen && selectedTexture && (
                <TextureDetailsModal
                    texture={selectedTexture}
                    onClose={() => setIsDetailsModalOpen(false)}
                    textureType={textureTypes.find(type => type.id === selectedTexture.textureTypeId)}
                />
            )}
        </div>
    );
};

export default TextureLibrary;
