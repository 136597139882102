import React, { useState } from "react";
import axios from "axios";

interface FileUploadProps {
  onUploadComplete: (file: { id: number; fileName: string; filePath: string }) => void;
  label: string;
  jobBordId: number;
  roadmapId: number;
}

const FileUpload: React.FC<FileUploadProps> = ({ onUploadComplete, label, jobBordId, roadmapId }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("jobBordId", jobBordId.toString());
    formData.append("roadmapId", roadmapId.toString());

    console.log("Uploading file:", formData);

    const apiBaseUrl = localStorage.getItem('api');
    try {
      const response = await axios.post(`${apiBaseUrl}/api/Upload/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      onUploadComplete(response.data);  // Notify parent component about the upload
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <label>{label}</label>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default FileUpload;
