import React, { useEffect, useState } from 'react';
import './TemplateFieldBuilder.css';
import axios from 'axios';

export interface TemplateField {
  id: string;
  name: string;
  type: 'text' | 'number' | 'nested' | 'array' | 'subcategory';
  fields?: TemplateField[];
}

interface TemplateFieldBuilderProps {
  template: TemplateField[];
  onSave: (templateName: string, template: TemplateField[]) => void;
  onCancel: () => void;
}

const mandatoryFields: TemplateField[] = [
  { id: '1', name: 'Name', type: 'text' },
  { id: '2', name: 'Image', type: 'text' },
  { id: '3', name: 'Country', type: 'text' }
];

const TemplateFieldBuilder: React.FC<TemplateFieldBuilderProps> = ({
  template,
  onSave,
  onCancel,
}) => {
  const [templateName, setTemplateName] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState<'text' | 'number' | 'nested' | 'array' | 'subcategory'>('text');
  const [templateState, setTemplate] = useState<TemplateField[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);

  useEffect(() => {
    setTemplate(template);
  }, [template]);

  useEffect(() => {
    if (fieldType === 'subcategory') {
      fetchSubCategories();
    }
  }, [fieldType]);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${localStorage.getItem("api")}/api/GameDatabase/subcategories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      setSubCategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const addField = () => {
    if (fieldName.trim() === '') {
      alert('Field name cannot be empty.');
      return;
    }

    const newField: TemplateField = {
      id: `${Date.now()}`,
      name: fieldName,
      type: fieldType,
      fields: fieldType === 'nested' || fieldType === 'array' ? [] : undefined,
    };

    // Store subcategory as "subcategory$id" string if it's a subcategory type
    if (fieldType === 'subcategory' && selectedSubCategory) {
      newField.name = `${fieldName}$${selectedSubCategory}`;
    }

    const updatedTemplate = [...templateState, newField];
    setTemplate(updatedTemplate);
    setFieldName('');
    setFieldType('text');
    setSelectedSubCategory(null);
  };

  const handleSave = () => {
    if (templateName.trim() !== '') {
      onSave(templateName, [...mandatoryFields, ...templateState]);
    } else {
      alert('Template name cannot be empty.');
    }
  };

  const renderFields = (fields: TemplateField[], level = 0) => {
    return fields.map((field) => (
      <div key={field.id} style={{ marginLeft: `${level * 20}px` }}>
        <span>{field.name.includes('$') ? field.name.split('$')[0] : field.name} ({field.type})</span>
        {field.type === 'array' || field.type === 'nested' ? (
          <div>{renderFields(field.fields || [], level + 1)}</div>
        ) : null}
      </div>
    ));
  };

  return (
    <div className="gamedb-template-field-builder">
      <div className="gamedb-template-header">
        <h3>Create Template</h3>
        <input
          type="text"
          placeholder="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </div>
      <div className="gamedb-template-fields">
        {mandatoryFields.map((field) => (
          <div key={field.id} className="gamedb-template-field mandatory">
            <span>{field.name} ({field.type})</span>
          </div>
        ))}
        {renderFields(templateState)}
      </div>
      <div className="gamedb-add-field">
        <input
          type="text"
          placeholder="Field Name"
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
        />
        <select
          value={fieldType}
          onChange={(e) => setFieldType(e.target.value as any)}
        >
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="nested">Nested</option>
          <option value="array">Array</option>
          <option value="subcategory">SubCategory</option>
        </select>
        {fieldType === 'subcategory' && (
          <select
            value={selectedSubCategory || ''}
            onChange={(e) => setSelectedSubCategory(e.target.value)}
          >
            <option value="">Select SubCategory</option>
            {subCategories.map((subCategory) => (
              <option key={subCategory.id} value={subCategory.id}>
                {subCategory.name}
              </option>
            ))}
          </select>
        )}
        <button onClick={addField}>Add Field</button>
      </div>

      <div className="gamedb-template-actions">
        <button className="gamedb-btn-cancel" onClick={onCancel}>Cancel</button>
        <button className="gamedb-btn-save" onClick={handleSave}>Save Template</button>
      </div>
    </div>
  );
};

export default TemplateFieldBuilder;
