import React, { useState } from 'react';
import { FaCamera, FaLock, FaUserPlus, FaClipboard } from 'react-icons/fa';
import axios from 'axios';
import './ProfileModal.css';
const apiBaseUrl = localStorage.getItem("api");

const ProfileModal: React.FC<{ user: { name: string; image?: string; role?: string }; onClose: () => void }> = ({ user, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(user.image || null);
  const [newUserName, setNewUserName] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/api/Users/changePassword`, {
        newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      if (response.status === 200) {
        alert('Password changed successfully');
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  const handleSaveImage = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append('image', selectedImage);

    try {
      const response = await axios.post(`${apiBaseUrl}/api/Users/uploadProfileImage`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      if (response.status === 200) {
        alert('Profile image updated successfully');
        setSelectedImage(null);

        const updatedUser = { ...user, image: response.data.imageUrl };
        localStorage.setItem('user', JSON.stringify(updatedUser));
      }
    } catch (error) {
      console.error('Error uploading profile image:', error);
    }
  };

  const handleCreateUser = async () => {
    if (!newUserName) {
      alert('Please enter a username.');
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/api/Users/create`, {
        name: newUserName,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      if (response.status === 200) {
        setGeneratedPassword(response.data.password);
        alert(`User ${newUserName} created successfully.`);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const copyToClipboard = () => {
    if (generatedPassword) {
      navigator.clipboard.writeText(generatedPassword);
      alert('Password copied to clipboard');
    }
  };

  return (
    <div className="profile-modal">
      <div className="profile-modal-content">
        <div className="profile-header">
          <img src={previewImage || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'} alt="Profile" className="profile-image" />
          <div className="profile-info">
            <h2>{user.name}</h2>
            <label className="upload-button">
              <FaCamera /> Upload New Photo
              <input type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
            </label>
            {selectedImage && <button onClick={handleSaveImage} className="save-button">Save Image</button>}
          </div>
        </div>
        <div className="change-password">
          <h3><FaLock /> Change Password</h3>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button onClick={handlePasswordChange} className="change-password-button">Change Password</button>
        </div>
        {user.role === 'god' && (
          <div className="create-user">
            <h3><FaUserPlus /> Create New User</h3>
            <input
              type="text"
              placeholder="New Username"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
            />
            <button onClick={handleCreateUser} className="create-user-button">Create User</button>
            {generatedPassword && (
              <div className="generated-password">
                <p>Generated Password: {generatedPassword}</p>
                <button onClick={copyToClipboard} className="copy-button"><FaClipboard /> Copy to Clipboard</button>
              </div>
            )}
          </div>
        )}
        <button onClick={onClose} className="close-modal-button">Close</button>
      </div>
    </div>
  );
};

export default ProfileModal;
