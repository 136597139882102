import React, { useEffect } from 'react';
import './SuccessModal.css';

interface SuccessModalProps {
  message: string;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 1000); // Hide the modal after 1 second
    return () => clearTimeout(timer); // Clear the timeout if the component is unmounted
  }, [onClose]);

  return (
    <div className="success-modal">
      <div className="success-modal-content">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default SuccessModal;
